import { render, staticRenderFns } from "./kasus.vue?vue&type=template&id=1958c16d&scoped=true"
import script from "./kasus.vue?vue&type=script&lang=js"
export * from "./kasus.vue?vue&type=script&lang=js"
import style0 from "./kasus.vue?vue&type=style&index=0&id=1958c16d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1958c16d",
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QImg,QFile,QIcon,QCardActions,QChatMessage,QSelect,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QImg,QFile,QIcon,QCardActions,QChatMessage,QSelect})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
