

  
  export default {

   
    data() {
      return {
        form : {
          id : '',
          judul_kasus : '',
          tanggal_kasus: '',
          waktu_kasus:'',
          alamat_kasus:'',
          catatan_kasus:'',
          deskripsi_kasus:'',
          status_kasus:'',
          latitude: 0,
          longitude: 0,
          file : null,
          file_old : null,
          bukti_kasus: null
        },

        // audio: {
        //   sources: [
        //     {
        //       src: 'https://raw.githubusercontent.com/quasarframework/quasar-ui-qmediaplayer/dev/demo/public/media/Scott_Holmes_-_04_-_Upbeat_Party.mp3',
        //       type: 'audio/mp3'
        //     }
        //   ]
        // },

        status_user: 0,

        status_pengguna: null,

        play: false,
       
        list_data : [],
        list_petugas : [],
        list_pegawai : [],

        no_telp: null,

  
        page_first: 1,
        page_last: 0,
        cari_value: "",
        cek_load_data : true,
        file_path: this.$store.state.url.URL_APP + "uploads/",
  
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_lihat : false,
        mdl_chat : false,
        mdl_status : false,
        mdl_pegawai : false,
        btn_add: false,
        mdl_sirine: false,
        mdl_clear_satgas: false,
        

        selectedOptions: [],
        optionnya: [
          { value: '0', label: 'Lapor' },
          { value: '1', label: 'Proses' },
          { value: '2', label: 'Batal' },
          { value: '3', label: 'Selesai' }
        ]

      }
    },
    methods: {
  

      async removeAllDevices() {
        if (this.status_pengguna != 8 || this.status_pengguna != 6 || this.status_pengguna != 7)  {
          try {
            const response = await fetch('https://server-firetap.konaweselatankab.go.id/api/v1/updateLocation/remove-all-devices', { // Ganti dengan URL server Anda
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            
            if (response.ok) {
              console.log('All devices removed');
            } else {
              console.error('Failed to remove all devices');
            }
          } catch (error) {
            console.error('Error removing all devices:', error);
          }
      }
    },

      matikanAlarm : function(){

        if (this.status_pengguna != 8 || this.status_pengguna != 6 || this.status_pengguna != 7)  {
            
            const audio = document.querySelector("#xxx");
            audio.pause();
            audio.currentTime = 0;          


            fetch(this.$store.state.url.URL_LOKASI + "matikanSirine", {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                  authorization: "xxx " + localStorage.token
                },
                body: JSON.stringify({
                    bunyi: 0
                })
            }).then(res_data => {
                this.Notify('Sukses Mematikan Sirine', 'primary', 'check_circle_outline');

            });


        } 
      },

    
      toWA : function(){
        window.open(`https://wa.me/+62`+this.no_telp, "_blank");
      },
  
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_Kasus + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                status_pengguna: this.status_pengguna
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;



                // if (this.list_data.length > 0) {
                //   this.status_user = this.list_data[0].status_user;
                // } else {
                //   this.status_user = 0;
                // }
                


                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
            });
      },
  
      getPetugas : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_Kasus + "petugas", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                id_kasus: this.form.id
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_petugas = res_data.data;
                console.log(this.list_petugas);

                this.$store.commit("hideLoading");
        });
      },

      getPegawai : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_Pegawai + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: 1,
                cari_value: ""
            })
        })
            .then(res => res.json())
            .then(res_data => {
                
                var arrayku = [];
                
                for(var i = 0;i<res_data.data.length;i++){
                  arrayku.push({value: res_data.data[i].id, label:res_data.data[i].nama_pegawai})

                }
                this.list_pegawai = arrayku;

                this.$store.commit("hideLoading");
        });
      },

      
      editStatus : function() {


        fetch(this.$store.state.url.URL_Kasus + "editStatus", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id_kasus: this.form.id,
                status_kasus: this.form.status_kasus.value,
                deskripsi_kasus: this.form.deskripsi_kasus,
                
            })
        }).then(res_data => {
            this.Notify('Sukses Mengubah Data', 'primary', 'check_circle_outline');


            this.getView();
        });
      },

      addPetugas : function() {
        fetch(this.$store.state.url.URL_Kasus + "addPetugas", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id_kasus: this.form.id,
                id_petugas: this.selectedOptions
            })
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');


            this.getPetugas();
        });
      },
  
      addData : function() {
        var formData = new FormData();
        formData.append('data', JSON.stringify(this.form))
        formData.append('file', this.form.file)
  
        // console.log(formData);
  
        fetch(this.$store.state.url.URL_Kasus + "addData", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');

            // document.getElementById("myAudio").play();

            this.getView();
        });
      },
  
  
      editData : function(){
        var formData = new FormData();
        formData.append('data', JSON.stringify(this.form))
        formData.append('file', this.form.file)
        fetch(this.$store.state.url.URL_Kasus + "editData", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(a, b){
        // console.log(a);
        // console.log(b);
        fetch(this.$store.state.url.URL_Kasus + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : a, file : b})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },

      hapusPegawai : function(id_kasus, id_pegawai){
        // console.log(a);
        // console.log(b);
        fetch(this.$store.state.url.URL_Kasus + "hapusPegawai", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id_kasus : id_kasus, id_pegawai : id_pegawai})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getPetugas();
        });
  
      },

      selectData: function(data) {
          this.form.id = data.id
          this.form.judul_kasus = data.judul_kasus
          this.form.alamat_kasus = data.alamat_kasus
          this.form.tanggal_kasus = data.tanggal_kasus
          this.form.catatan_kasus = data.catatan_kasus
          this.form.waktu_kasus = data.waktu_kasus
          this.form.deskripsi_kasus = data.deskripsi_kasus
          this.form.status_kasus = data.status_kasus
          this.form.latitude = data.latitude
          this.form.longitude = data.longitude
        //   this.form.file = this.form.file
          this.form.file = data.foto_kasus
          this.form.createAt = data.createAt
          this.form.file_old =  data.foto_kasus
          this.form.bukti_kasus =  data.bukti_kasus



          this.no_telp = data.no_telp
      },
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },
  
          onFileSelected: function(event) {
            this.form.file = event.target.files[0];
          },
  
  
      // ====================================== PAGINATE ====================================
     
      
    },
  
    mounted () {

      var get_profile = JSON.parse(localStorage.profile);

      
      this.id = get_profile._id;
      this.status_pengguna = get_profile.profile.status;

      this.getView();
      this.getPegawai();

       //To Array Alarm
        // this.$binding("alarm", firestore.collection("alarm"))
    },

    
  }
  